<template>
  <div>
    <b-card>
      <div class="app_title_box">
        <h4>Bad Reviews</h4>
        <!-- <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="showModal"
        >
          New
        </b-button> -->
      </div>
      <div class="mb-2">
        <b-row>
          <b-col cols="3">
            <b-form-group
              label="Search bad reviews"
              label-for="v-search-keyword"
            >
              <b-form-input
                id="h-search-keyword"
                v-model="filter"
                type="text"
                placeholder="search here..."
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="bad_reviews"
        sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        :filter-included-fields="filterOn"
        @row-clicked="showDetails"
      >
      <template #cell(review.reviewer)="data">
          {{ data.value.first_name }} {{ data.value.last_name }}
        </template>
        <template #cell(review.review)="data">
          <div class="custom-width">{{ data.value }}</div>
        </template>
        <template #cell(review.rating)="data">
          <b-form-rating
            variant="warning"
            no-border
            :value="data.value"
            readonly
            inline
            class="raiting_box"
            style="border:none !important; padding-left: 0 !important;"
          />
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="per_page"
        size="lg"
        class="mt-3 pagination-success"
        align="center"
        @change="onPageChange"
      />
    </b-card>

    <b-modal
      id="app-modal"
      title="Reported Review Details"
      hide-footer
      size="lg"
    >
      <b-card-text v-if="review !== null">
        <div
          class="business_reviw__card"
        >
          <div class="reviewer_view">
            <b-avatar
              v-if="review.reviewer.avatar === null || review.reviewer.avatar === ''"
              variant="success"
              :text="getUserText(review.reviewer.first_name, review.reviewer.last_name)"
              size="50"
            />
            <b-avatar
              v-else
              :src="getImage(review.reviewer.avatar)"
              size="50"
            />
            <div class="review-heading_box">
              <div class="heading">
                <h4>{{ review.reviewer.first_name }} {{ review.reviewer.last_name }}, {{ review.reviewer.profession }}</h4>
                <p style="margin-top: -10px;"> <b-form-rating
                  variant="warning"
                  no-border
                  :value="review.rating"
                  readonly
                  inline
                  class="raiting_box"
                  style="border:none !important; padding-left: 0 !important;"
                /></p>
              </div>
              <div />

            </div>

          </div>
          <div class="mb-3">
            <b-badge
              :variant="review.status === 'active' ? 'light-success':
                review.status === 'reported' ? 'light-danger' : review.status === 'under review' ? 'light-warning' : 'light-secondary'
              "
            >
              Review {{ review.status }}
            </b-badge>
          </div>
          <blockquote class="blockquote pl-1 border-left-success border-left-3">
            {{ review.review }}
          </blockquote>

          <div class="row mt-2">
            <div class="col-md-4 mb-2">
              <h5>Customer Service</h5>
              <span>{{ review.content.customer_service }}</span>
            </div>

            <div class="col-md-3 mb-2">
              <h5>Pricing</h5>
              <span>{{ review.content.pricing }}</span>
            </div>

            <div class="col-md-3 mb-2">
              <h5>Quality</h5>
              <span>{{ review.content.quality }}</span>
            </div>

            <div class="col-md-4 mb-2">
              <h5>Timeliness</h5>
              <span>{{ review.content.timeliness }}</span>
            </div>

            <div class="col-md-3 mt-2">
              <h5>Recommendation</h5>
              <span>{{ review.content.recommend }}</span>
            </div>
          </div>

          <!-- <hr> -->
          <!-- <b-alert
            variant="danger"
            show
          >
            <h4 class="alert-heading">
              Report
            </h4>
            <div class="alert-body">
              <span>{{ review.report }}</span>
            </div>
          </b-alert> -->
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BButton, BModal, VBModal, BCardText, BTable, BAvatar, BPagination,
  BFormInput,
  BRow, BCol, BFormGroup, BFormRating, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BButton,
    BModal,
    BCardText,
    BTable,
    BAvatar,
    BPagination,
    BFormInput,
    BRow,
    BCol,
    BBadge,
    BFormRating,
    BFormGroup,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      is_update: false,
      fields: [{ key: 'id', label: '#ID' }, { key: 'business_profile.Name', label: 'Business Name', sortable: true, variant: 'custom-width' }, { key: 'review.reviewer', label: 'Reviewer', sortable: true }, { key: 'review.review', label: 'Review', sortable: true }, { key: 'review.rating', label: 'Ratings', sortable: true }],
      currentPage: 1,
      per_page: 10,
      sortBy: 'id',
      sortDesc: false,
      searchTerm: '',
      filter: null,
      filterOn: [],
      review: null,
    }
  },
  computed: {
    bad_reviews() {
      const data = this.$store.state.review.bad_reviews
      // const items = data.map(item => item.review)
      return data
    },
    totalRows() {
      return this.bad_reviews.length
    },
  },
  mounted() {
    this.getBadReviews()
  },
  methods: {
    getBadReviews() {
      return this.$store.dispatch('review/getBadReviews', { page: 1, per_page: this.per_page })
    },
    onPageChange(page) {
      this.$store.dispatch('review/getBadReviews', { page, per_page: this.per_page })
    },
    getImage(image) {
      if (image !== null || image !== '') {
        return process.env.VUE_APP_STORAGE_PATH + image
      }
      return image
    },
    showModal() {
      this.$bvModal.show('app-modal')
    },
    hide() {
      this.$bvModal.hide('app-modal')
    },
    showDetails(item) {
      this.review = item.review
      this.showModal()
    },
    getUserText(firstName, lastName) {
      const firstChar = firstName.charAt(0)
      const lastChar = lastName.charAt(0)
      return firstChar + lastChar
    },
  },
}
</script>

<style scoped>
.custom-width {
  max-width: 900px !important;
}
</style>
